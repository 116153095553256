<template>
  <v-footer padless>
    <v-card flat tile width="100%" class=" text-center" color="#000000">
      <v-card-text class="white--text pb-0">
        Copyright &copy;
        {{ new Date().getFullYear() }}
        <strong>Bernardo Bernal</strong>. Todos los derechos reservados.
      </v-card-text>
      <v-card-actions class="justify-center text-center py-0">
        <v-btn
          dark
          icon
          class="ml-2"
          target="_blank"
          href="https://api.whatsapp.com/send?phone=524441176494&text=Hola,%20me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n."
        >
          <v-icon color="white">mdi-whatsapp</v-icon>
        </v-btn>
        <v-btn
          dark
          class="ml-2"
          icon
          target="_blank"
          href="https://www.linkedin.com/in/jose-bernardo-bernal-cabrera-773a24116"
        >
          <v-icon color="white">mdi-linkedin</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
export default {};
</script>
